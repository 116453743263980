@import "~@/styles/variables/variables.scss";



































































































.layout {
	width: 100vw;
	height: 100vh;
	display: flex;

	&-left {
		transition: width 0.3s;
		z-index: 1; // 凸显右侧阴影
	}

	&-right {
		flex: 1;
		width: 0;
		.layout-top {
			height: 60px;
			background: #fff;
			position: relative;
			border-bottom: 1px solid #f1f1f1;
			.layout-header {
				height: 60px;
			}
		}

		.layout-main {
			height: calc(100vh - 94px);
			background: #fff;
			overflow: hidden;
		}
	}
}
