@import "~@/styles/variables/variables.scss";






























































































































































































































































































.chartTooltip {
	min-width: 280px;
	.title {
		margin-top: 5px;
	}
	.content {
		padding-top: 3px;
		display: flex;
		justify-content: space-between;
	}
}
