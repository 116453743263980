.sidebar {
	background: $menu-color-bg;
	display: flex;
	flex-direction: column;
	.box-logo {
		cursor: pointer;
		height: 156px;
		@include flex-center;
		.logo {
			width: 43px;
		}
		.logo-szrq {
			width: 100px;
			&.small {
				width: 70px;
			}
		}
		.title {
			margin-left: 12px;
			height: 33px;
			line-height: 33px;
			font-size: 22px;
			font-weight: 500;
			font-family: SourceHanSansCN-Regular, SourceHanSansCN;
			color: #104373;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	.box-menu {
		flex: 1;
		overflow-y: scroll;
	}
	.bounce {
		animation: bounce 2s infinite;
	}

	@keyframes bounce {
		0%,
		100% {
			top: 0;
		}
		50% {
			top: -5px;
		}
	}
	.box-robot {
		height: 220px;
		text-align: center;
		position: relative;
		.icon-robot {
			cursor: pointer;
			width: 186px;
			height: 183px;
		}
		.entry-tip {
			display: block;
			width: 130px;
			position: absolute;
			z-index: 1;
			height: 36.32px;
			top: 0;
			background: url('../assets/imgs/pic/tip-bg.png') no-repeat;
			background-size: 100%;
			left: 50%;
			transform: translateX(-50%);
			line-height: 30px;
			font-family: Source Han Sans CN;
			font-size: 13px;
			font-weight: 400;
			text-align: center;
			color: rgba(255, 255, 255, 1);
		}
		.icon-robot-hide {
			cursor: pointer;
			position: absolute;
			left: 0;
			bottom: 100px;
			width: 80px;
			height: 80px;
		}
	}
	.el-menu {
		background: $menu-color-bg;
		border: none;
		.sidebar-item {
			margin-bottom: 20px;
			&[is-nest] {
				margin-bottom: 0;
				.el-menu-item .title {
					margin-left: 24px;
				}
			}
		}
		.item-link,
		.el-submenu {
			display: block;
			padding: 0 25px;
			.item-link {
				padding: 0;
			}
			.el-menu-item {
				min-width: initial;
			}
		}
		.el-submenu__title,
		.el-menu-item {
			height: 50px;
			line-height: 50px;
			border-radius: 8px;
			color: #9a9eb3;
			.iconfont {
				color: #9a9eb3;
				font-size: 20px;
			}
			.title {
				margin-left: 14px;
			}
		}
		.el-submenu__title {
			margin-bottom: 10px;
		}
		.el-menu-item {
			border-radius: 8px;
			&.is-active {
				color: #fff;
				background: $base-color-theme;
				position: relative;
				&::before {
					content: '';
					position: absolute;
					width: 4px;
					height: 100%;
					top: 0;
					left: -25px;
					background: $base-color-theme;
					border-radius: 0 2px 2px 0;
				}
				.iconfont {
					color: #fff;
				}
			}
		}
		// 导航折叠样式
		&.el-menu--collapse {
			width: 80px;
			padding: 0;
			.item-link,
			.el-submenu {
				padding: 0 18px;
				&.is-active .el-submenu__title {
					background: $base-color-theme;
					.iconfont,
					.title {
						color: #fff;
					}
				}
			}
			.el-submenu__title,
			.el-menu-item {
				padding: 10px 0 !important;
				border-radius: 8px;
				height: 62px;
				text-align: center;
				line-height: initial;
				&.is-active {
					background: $base-color-theme;
					i {
						color: #fff;
					}
				}
				.title {
					margin-left: 0;
					margin-top: 3px;
				}
			}
			.el-menu-item,
			.el-submenu__title {
				.title {
					display: block;
				}
			}
			.el-submenu__icon-arrow {
				display: none;
			}
		}
	}
	::-webkit-scrollbar {
		opacity: 0;
		display: none;
	}
}

.sidebar-submenu {
	.el-menu--popup {
		padding: 0;
		min-width: 144px;
		border-radius: 8px;
		overflow: hidden;
		&.el-menu--popup-right-start {
			margin-left: 0;
		}
		.el-menu-item {
			height: 44px;
			line-height: 44px;
			padding-left: 30px !important;
			&:hover,
			&:focus {
				color: #fff;
				background: $base-color-theme;
			}
		}
	}
	&.is-active {
		.el-menu {
			background: $base-color-theme;
			.el-menu-item {
				color: #fff;
				&.is-active {
					color: #fff;
					background: #3e56cc;
				}
			}
		}
	}
}
