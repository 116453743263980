@import "~@/styles/variables/variables.scss";// 全局样式入口
@import './normalize.scss';
@import './reset.scss';
@import './element-ui.scss';
@import './common.scss';
@import './sidebar.scss';
@import './dotStatus.scss';
@import './alarm-notify.scss';

html body {
	position: relative;
	box-sizing: border-box;
	height: 100vh;
	overflow: hidden;
	font-family: SourceHanSansCN-Regular, SourceHanSansCN;
	font-size: $base-font-size;
	color: $base-color-text;
	background: $base-color-background;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	@include base-scrollbar;

	#app {
		height: 100%;
	}

	* {
		box-sizing: border-box;
		outline: none !important;
		@include base-scrollbar;
	}

	a {
		color: $base-color-theme;
		text-decoration: none;
		cursor: pointer;
	}

	img {
		object-fit: cover;
	}

	#nprogress {
		position: fixed;
		z-index: 9999;
		.bar {
			background: $base-color-theme;
		}
		.peg {
			box-shadow: 0 0 10px $base-color-theme, 0 0 5px $base-color-theme;
		}
	}
	.amap-logo,
	.amap-copyright {
		display: none !important;
	}
	.amap-icon img {
		width: 100% !important;
		height: 100% !important;
	}
}
