@import "~@/styles/variables/variables.scss";


































































































































































































































































































































































































.gc-tree-select-drop-down {
	padding: 16px 12px !important;
	max-height: 230px;
	overflow-y: scroll;
	box-sizing: border-box;
	.drop-down-content {
		height: 100%;
		overflow: hidden;
		max-height: 194px;
		.drop-down-tree {
			max-height: 168px;
			overflow-y: scroll;
		}
		.btn-area {
			float: right;
			height: 26px;
			.mini {
				padding: 9px 15px;
				font-size: 12px !important;
				border-radius: 3px;
				line-height: 6px;
				height: auto !important;
			}
		}
	}

	.el-tree {
		margin-top: 0 !important;
		.el-tree-node {
			&__content {
				height: 36px;
				border-radius: 4px;
				.el-icon-arrow-right:not(.is-leaf) {
					color: #3f435e;
					font-size: 10px;
					font-weight: bold;
				}
				.custom-tree-node {
					flex: 1;
					width: 0;
					align-items: center;
					color: #3f435e;
					font-size: 14px;
					.el-dropdown-menu__item {
						width: 100%;
						line-height: 36px;
						padding: 0;
						&:hover {
							background-color: transparent;
							color: #4d6bff;
						}
					}
				}
			}
			&.is-current {
				& > .el-tree-node__content {
					.custom-tree-node {
						.el-dropdown-menu__item {
							color: #4d6bff;
							font-weight: 600;
						}
					}
				}
			}
		}
	}
}
