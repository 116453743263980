@import "~@/styles/variables/variables.scss";






























































.gc-number-range {
	border: 1px solid #eee;
	border-radius: 8px;
	display: flex;
	align-items: center;
	transition: 0.3s;
	width: 100%;
	.gc-range-separator {
		color: #4c4c4c;
		margin: 0 8px;
	}

	::v-deep .el-input-number {
		flex: 1;
		width: 0;
	}
	::v-deep .el-input {
		width: 100%;
		.el-input__inner {
			border: none;
			height: 16px;
			line-height: 16px;
		}
	}
	&:has(.el-input__inner:focus) {
		border-color: #4d6bff;
	}
}
