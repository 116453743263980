@import "~@/styles/variables/variables.scss";





















































































































































































.column-setting-popover {
	width: auto !important;
	min-width: 220px;
	.column-set-modal {
		padding-left: 8px;
		.column-list {
			&-item {
				display: flex;
				padding: 4px 0;
				align-items: center;
				justify-content: space-between;
				&.hidden {
					display: none;
				}
				.drag-icon {
					width: 8px;
					cursor: move;
					user-select: none;
					margin-left: 6px;
				}
			}
		}
	}
	.bottom-operate {
		display: flex;
		margin-top: 12px;
		.el-button {
			padding: 8px 12px;
			font-size: 12px !important;
			height: auto;
			border-radius: 4px;
			&.el-button--text {
				padding: 0;
				margin-right: auto;
			}
		}
	}
}
