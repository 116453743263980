@import "~@/styles/variables/variables.scss";

































.gc-blank-page {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	z-index: 1;
	color: #999;
	margin-top: 106px;
	.no-data-img {
		width: 155px;
		height: 139px;
	}
	.text {
		margin-top: 13px;
		font-size: 14px;
		color: #999999;
		line-height: 21px;
	}
	::v-deep .el-button {
		margin-top: 40px;
	}
	.disabled {
		border: 1px solid #accfff;
		color: #accfff;
		&:hover {
			cursor: not-allowed;
			background-color: #fff;
		}
	}
}
