@import "~@/styles/variables/variables.scss";


























.gc-sub-title {
	display: flex;
	align-items: center;
	.sub-title-icon {
		margin-right: 12px;
		@include flex-center;
		.icon {
			width: 19px;
			height: 19px;
		}
	}
	.sub-title-text {
		flex: 1;
		font-size: 16px;
		font-weight: 600;
		color: #333333;
		line-height: 24px;
	}
	.sub-title-slot {
	}
}
