@import "~@/styles/variables/variables.scss";







































.group-detail-item {
	width: 25%;
	margin-top: 20px;
	flex-shrink: 0;
	box-sizing: border-box;
	display: flex;
	padding-right: 20px;
	.group-detail-name,
	.group-detail-info {
		line-height: 21px;
	}
	.group-detail-name {
		color: #999;
		flex-shrink: 0;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.group-detail-info {
		flex: 1;
		width: 0;
		div {
			color: #333;
			p {
				word-break: break-all;
			}
		}
	}
}
