@import "~@/styles/variables/variables.scss";

































































































































































































































.gc-region-tree {
	height: 100%;
	display: flex;
	flex-direction: column;
	.tree-content {
		flex: 1;
		height: 0;
		padding-top: 12px;
		::v-deep .el-tree {
			height: 100%;
			overflow-y: scroll;
			.el-tree-node {
				&__content {
					height: 42px;
					border-radius: 4px;
					.el-icon-caret-right:not(.is-leaf) {
						color: #3f435e;
						font-size: 12px;
						font-weight: bold;
					}
					.custom-tree-node {
						display: flex;
						flex: 1;
						width: 0;
						align-items: center;
						color: #3f435e;
						line-height: 22px;
						.icon {
							margin-right: 4px;
							&.w_15 {
								width: 15px;
							}
							&.w_17 {
								width: 17px;
							}
						}
						p {
							flex: 1;
							width: 0;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
					}
					&:hover {
						background-color: #f4f5fb;
					}
				}
				&.is-current {
					& > .el-tree-node__content {
						.custom-tree-node:not(.noHighLight) {
							color: #4d6bff;
							font-weight: 500;
						}
					}
				}
			}
		}
	}
}
