@import "~@/styles/variables/variables.scss";












































































































































.gc-detail-tab {
	background-color: #eceff8;
	width: 100%;
	height: 100%;
	::v-deep .el-tabs__nav-scroll {
		font-size: 14px;
		overflow: hidden;
		border: 1px solid #fff;
		background-color: #fff;
		border-bottom: none;
		.el-tabs__item {
			color: #8795a9;
			font-size: 16px;
			&.is-top:last-child {
				padding-right: 20px;
			}
			&.is-top:nth-child(2) {
				padding-left: 20px;
			}
		}
		.is-active {
			color: #4d6bff;
		}
		.is-disabled {
			color: #b3b3b3;
			cursor: not-allowed;
		}
	}
	::v-deep .el-tabs__nav-wrap {
		margin-bottom: 0;
		&.is-scrollable {
			background-color: #fff;
		}
		&:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 2px;
			background-color: transparent;
			z-index: 1;
		}
	}
	::v-deep .el-tabs__content {
		height: calc(100% - 42px);
		background-color: #fff;
		.el-tab-pane {
			height: 100%;
			// overflow: auto;
		}
	}
	.el-tabs.el-tabs--top {
		height: 100%;
	}
	// 隐掉tab的边框
	::v-deep .el-tabs__item:focus.is-active.is-focus:not(:active) {
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	::v-deep .el-tabs__header {
		margin-bottom: 0;
		z-index: 999;
		border-bottom: 1px solid #f1f1f1;
		.el-tabs__active-bar {
			height: 4px;
			background: #4d6bff;
			border-radius: 100px 100px 0px 0px;
		}
	}
}
