@import "~@/styles/variables/variables.scss";







































.photo {
	width: 100px;
	height: 100px;
	margin-right: 16px;
	margin-bottom: 16px;
}
