@import "~@/styles/variables/variables.scss";















































































.scroll-container {
	white-space: nowrap;
	position: relative;
	overflow: hidden;
	width: 100%;
	// height: 34px;
	// box-sizing: border-box;
	::v-deep {
		.el-scrollbar__bar {
			bottom: 0px;
			display: none;
		}
		.el-scrollbar__wrap {
			height: 51px;
		}
	}
}
