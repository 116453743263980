@import "~@/styles/variables/variables.scss";


























































































































































































































































































































$base-font-size: 14px;
.disabled {
	background-color: #f5f7fa;
}
.gc-custom-search {
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 11px 0 11px 12px;
	border: 1px solid #eee;
	border-radius: 6px;
	&.show-import {
		max-height: 40px;
	}
	::v-deep .left {
		padding-right: 12px;
		color: #666;
		flex-shrink: 0;
		border-right: 1px solid #eee;
		.el-input--small,
		.el-input--suffix {
			.el-input__inner {
				display: -webkit-box;
				text-overflow: ellipsis;
				white-space: nowrap;
				height: 14px;
				line-height: 14px;
				padding: 0 20px 0 0;
				border: 1px solid transparent;
			}
			.el-input__icon {
				line-height: 14px;
				width: 15px;
			}
			.el-select .el-input.is-focus .el-input__inner {
				border-color: transparent;
			}
		}
	}
	::v-deep .content {
		flex: 1;
		width: 0;
		padding-left: 12px;
		display: flex;
		align-items: center;
		&:has(.el-date-editor--date) {
			padding-left: 0;
		}
		.el-button {
			padding: 4px 10px;
			height: auto !important;
			font-size: 12px !important;
			border-radius: 4px;
		}
		.el-date-editor {
			padding-left: 36px;
			.el-input__prefix {
				top: 1px;
			}
		}
		.el-range-editor--small {
			position: relative;
			.el-range-separator {
				width: 10%;
				line-height: 16px;
				font-size: $base-font-size;
				color: #cccccc;
				padding: 0;
			}
			.el-range__close-icon,
			.el-icon-circle-close:before {
				display: inline-block;
				line-height: 16px;
			}
			.el-range__icon {
				display: none;
			}
		}
		.el-input__inner {
			height: 16px;
			border: 1px solid transparent;
			padding: 0;
			width: 100%;
		}
		.el-input--small,
		.el-input--suffix {
			.el-input__inner {
				display: -webkit-box;
				text-overflow: ellipsis;
				white-space: nowrap;
				height: 16px;
				line-height: 16px;
				padding: 0 20px 0 0;
				border: 0px solid transparent;
			}
			.el-input__icon {
				line-height: 14px;
			}
		}
		.el-cascader--small {
			line-height: 16px;
		}

		.el-select {
			width: 100%;
			.el-input.is-focus .el-input__inner {
				border-color: transparent;
			}
		}

		.gc-number-range {
			border: none;
			.el-input__inner {
				padding: 0;
			}
		}
	}
	::v-deep .date-time {
		.el-input__inner {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			height: auto;
		}
		& input:nth-of-type(1) {
			padding-bottom: 18px;
			height: 32px;
		}
		.el-range-input {
			display: inline-block;
			width: 100%;
			height: 14px;
			text-align: left;
		}
		.el-input__icon {
			height: 0;
		}
		.el-range__close-icon {
			position: absolute;
			top: 50%;
			right: 0;
			margin-top: -7px;
		}
	}
	.unit {
		padding-right: 10px;
		color: #999;
		font-size: 14px;
	}
	.right {
		position: absolute;
		right: 5px;
		font-size: 14px;
		text-align: center;
		width: 25px;
		color: #b8bcc3;
	}
	&.required {
		&::before {
			position: absolute;
			display: inline-block;
			font-size: 14px;
			content: '*';
			color: red;
			top: 55%;
			left: 5px;
			transform: translateY(-50%);
		}
	}
}
