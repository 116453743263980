@import "~@/styles/variables/variables.scss";

































































































































































.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 15px 0 24px;

	&-left {
		@include flex-center;
		.iconfont {
			font-size: 14px;
			font-weight: bold;
			color: #000;
			cursor: pointer;
			transform: rotateY(0deg);
			position: relative;
			margin-right: 24px;
			&.fold {
				transform: rotateY(180deg);
			}
		}
	}

	&-right {
		height: 46px;
		padding: 10px 20px;
		background: #f0f1f5;
		border-radius: 6px;
		display: flex;
		align-items: center;
		.iconfont {
			font-size: 32px;
			color: #c1c6db;
			cursor: pointer;
			&.icon-screen {
				font-size: 17px;
			}
		}
		.screen-entry {
			margin-right: 26px;
			width: 86px;
			height: 26px;
			background: url('~@/assets/imgs/home/screen-entry.png') no-repeat rgba(86, 124, 237, 0.5);
			background-size: 100%;
			border-radius: 25px;
			overflow: hidden;
			cursor: pointer;
		}

		.user-info {
			margin-left: 26px;
			@include flex-center;
			cursor: pointer;
			.avatar {
				width: 36px;
				height: 36px;
			}
			.name {
				margin-left: 12px;
				.staff-name {
					font-weight: 500;
					color: #333;
				}
				.org-name,
				.tenant-name {
					margin-top: 4px;
					font-size: 12px;
					color: #999;
					max-width: 160px;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
			}
			i {
				margin-left: 6px;
			}
		}
	}
}
