@import "~@/styles/variables/variables.scss";











































































































































































::v-deep .el-dialog__wrapper {
	background: rgba(34, 34, 34, 0.7);

	+ .v-modal {
		display: none;
	}
}

::v-deep .gc-dialog-custom {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	max-width: 100%;

	.el-dialog__header {
		height: 58px;
		display: flex;
		align-items: center;
		padding: 0;
		padding-left: 15px;
	}

	.title {
		display: flex;
		align-items: center;
		.icon {
			width: 24px;
			height: 24px;
			margin-right: 4px;
		}
		&-content {
			font-size: 18px;
			font-weight: 400;
			color: #4d4d4d;
		}
	}

	.el-dialog__body {
		@include base-scrollbar(4px);
		overflow: auto;
		min-height: 58px;
		max-height: calc(100vh - 232px);
		box-sizing: border-box;
		padding: 0 24px;
	}
	&.no-padding .el-dialog__body {
		padding: 0;
	}

	.el-dialog__footer {
		padding: 0;
		padding-right: 20px;
		height: 80px;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.footer {
			.el-button {
				min-width: 80px;
				height: 32px;
				padding: 0 24px;
				border-radius: 16px;
				font-size: 14px;
				& + .el-button {
					margin-left: 10px;
				}
			}
		}
	}
}
