.box-notify.el-notification {
	width: 370px;
	padding: 0;
	border: none;
	.el-notification__group {
		width: 100%;
		margin: 0;
	}
	.el-notification__title {
		height: 40px;
		line-height: 40px;
		background: #e5662e;
		padding-left: 20px;
		font-weight: 600;
		font-size: 16px;
		color: #fff;
	}
	.el-notification__content {
		margin: 0;
		.content {
			display: flex;
			align-items: center;
			padding: 20px;
			background: rgba(229, 102, 46, 0.18);
			.box-icon {
				flex: 0 0 34px;
				.icon {
					width: 100%;
					height: 32.5px;
				}
			}
			.info {
				margin-left: 20px;
				.name {
					font-weight: 500;
					font-size: 16px;
					color: #4d4d4d;
				}
				.time,
				.address {
					margin-top: 6px;
					font-size: 12px;
					color: #999;
					line-height: normal;
				}
			}
		}
	}
	.el-notification__closeBtn {
		color: #fff;
		top: 12px;
		right: 12px;
		font-weight: bold;
	}

	// 大屏弹屏样式
	&.screen {
		width: 410px;
		background: rgba(49, 0, 0, 0.8);
		border: 1px solid #c6322b;
		border-radius: 3px;
		.el-notification__title {
			height: 40px;
			line-height: 40px;
			background: linear-gradient(90deg, #c6322b 0%, rgba(198, 50, 43, 0.4) 100%);
			font-size: 16px;
		}
		.el-notification__content {
			.content {
				padding: 15px 17px;
				display: flex;
				align-items: center;
				.box-icon {
					flex: 0 0 55px;
				}
				.info {
					margin-left: 18px;
					.name {
						font-weight: 600;
						font-size: 16px;
						color: #f41006;
					}
					.time,
					.address {
						margin-top: 6px;
						font-size: 13px;
						color: #fff;
					}
				}
			}
		}
		.el-notification__closeBtn {
			color: #fff;
			top: 12px;
			right: 12px;
			font-weight: bold;
		}
	}
}
