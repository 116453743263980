// 全局通用样式

.pointer {
	cursor: pointer;
}

/* 页面布局样式 */
.page {
	display: flex;
	flex-direction: column;
	height: 100%;
	&-main {
		flex: 1;
		display: flex;
		overflow: hidden;
		&-left {
			width: 360px;
			padding: 25px;
			overflow-y: auto;
			position: relative;
			&::after {
				content: '';
				position: absolute;
				right: 0;
				top: 25px;
				bottom: 25px;
				border-right: 1px dashed #f1f1f1;
			}
		}
		&-right {
			flex: 1;
			padding: 25px;
			overflow-y: auto;
		}
	}
}

.water-home-title {
	color: #333333;
	font-size: 18px;
	line-height: 30px;
	letter-spacing: 1.1px;
	font-weight: 500;
	background: linear-gradient(90deg, rgba(77, 107, 255, 0.1) 0%, rgba(77, 107, 255, 0) 100%);
	width: fit-content;
	padding-left: 12px;
	border-radius: 8px;
	margin: 5px 0;
}

@media screen and (min-width: 600px) and (max-width: 1400px) {
	.water-home-title {
		font-size: 16px;
	}
}

@font-face {
	font-family: 'DIN-Regular-2';
	src: url('~@/assets/font-family/DIN-Regular-2.otf');
}
