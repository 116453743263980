/* 
    状态点样式

*/
@mixin dot($dotColor) {
	font-size: 14px;
	color: #4e4e4e;
	line-height: 21px;
	&::before {
		display: inline-block;
		position: relative;
		content: '';
		margin-right: 4px;
		top: 50%;
		transform: translateY(-50%);
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background: $dotColor;
	}
	&.large {
		position: relative;
		&::before {
			margin-right: 26px;
			transform: initial;
			width: 12px;
			height: 12px;
		}
		&::after {
			content: '';
			position: absolute;
			left: -14px;
			top: 50%;
			transform: translateY(-50%);
			width: 36px;
			height: 36px;
			border-radius: 50%;
			background: $dotColor;
			opacity: 0.12;
		}
	}
}
.dot-error {
	@include dot($base-color-error);
}
.dot-success {
	@include dot($base-color-success);
}
.dot-message {
	@include dot($base-color-info);
}
.dot-warning {
	@include dot($base-color-warning);
}
