@import "~@/styles/variables/variables.scss";























































.z-page-group {
	padding: 30px 0 30px 0;
	.z-group-title {
		._title {
			font-size: 17px;
			color: #333;
			letter-spacing: 1px;
			font-weight: 600;
		}
	}
	.z-group-details {
		display: flex;
		flex-wrap: wrap;
	}
}
