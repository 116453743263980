@import "~@/styles/variables/variables.scss";































































































































































































































































































































































































































































































//
.tags-view-dropdown {
	.dropdown-item-wrapper {
		max-height: 400px;
		overflow-y: scroll;
	}
}

.vertical-tag {
	padding: 0 10px 0 16px !important;
	box-sizing: border-box;
	width: 120px;
	transition: all ease-in-out 0.2s;
	&:hover,
	&.active {
		background: #e8f2ff;
		color: #2f87fe;
	}
	.tags-view-item {
		height: 32px !important;
		line-height: 32px !important;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		.tag-title {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			word-break: break-all;
		}
	}
}
