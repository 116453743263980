@import "~@/styles/variables/variables.scss";

















































































































































































































































































































































































































































.gc-table {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	height: 100%;
	box-sizing: border-box;
	border-radius: 8px 8px 0 0;
	overflow: hidden;
	.table-show-box,
	.gc-table-box {
		height: 100%;
	}
	.is-show-page {
		height: calc(100% - 54px);
	}
	::v-deep .el-icon-caret-top {
		transition: All 0.4s ease-in-out;
		-webkit-transition: All 0.4s ease-in-out;
		-moz-transition: All 0.4s ease-in-out;
		-o-transition: All 0.4s ease-in-out;
	}
	::v-deep .el-icon-caret-top-rotate {
		transform: rotate(180deg);
		-webkit-transform: rotate(180deg);
		-moz-transform: rotate(180deg);
		-o-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
	}
	::v-deep .el-dropdown-link {
		cursor: pointer;
		color: #333;
		// &:hover {
		// }
	}
	::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
		background-color: #fff;
		border-color: #409eff;
	}
	::v-deep .el-checkbox__input.is-disabled .el-checkbox__inner {
		background-color: #f7f9fc;
		border-color: #dcdfe6;
	}
	::v-deep .el-checkbox__inner {
		width: 16px;
		height: 16px;
		&::after {
			border: 2px solid #409eff;
			border-left: 0;
			border-top: 0;
			left: 4px;
			top: 1px;
			width: 4px;
		}
	}

	::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
		background-color: #fff;
		border-color: #409eff;
	}
	::v-deep .el-checkbox__input.is-indeterminate.is-disabled .el-checkbox__inner {
		background-color: #f7f9fc;
		border-color: #dcdfe6;
		&::before {
			background-color: #dcdfe6;
		}
	}
	::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
		background-color: #409eff;
		top: 5px;
		height: 4px;
	}
	// 隐藏表头里面的全选框
	// ::v-deep .el-table__header-wrapper .el-checkbox {
	//   display: none;
	// }
	::v-deep .el-table th.gutter {
		/*
      * 解决element-ui 表格篡位的问题
      */
		display: table-cell !important;
		border: 1px solid transparent;
	}
	::v-deep .el-table--border {
		border: none;
	}
	::v-deep .show-table-border {
		.el-table tr {
			th:nth-child(1) {
				border-left: 1px solid $base-color-border;
			}
			td:nth-child(1) {
				border-left: 1px solid $base-color-border;
			}
			th:last-child {
				border-right: 2px solid $base-color-border;
				border-radius: 0 8px 0 0;
			}
			td:last-child {
				border-right: 2px solid $base-color-border;
			}
		}
		.el-table td {
			border-right: 1px solid $base-color-border;
		}
		.el-table th {
			border-top: 1px solid $base-color-border;
			border-right: 1px solid $base-color-border;
			&.gutter {
				border: 1px solid transparent !important;
			}
		}
		.el-table.el-table--scrollable-y {
			.el-table__body-wrapper.is-scrolling-none {
				padding-right: 2px;
				tr {
					th:last-child {
						border-right: 1px solid $base-color-border;
					}
					td:last-child {
						border-right: 1px solid $base-color-border;
					}
				}
			}
		}
	}
	::v-deep .el-table__body-wrapper {
		// padding-right: 2px;
	}
	::v-deep .el-table {
		color: #4e4e4e;
		& .cell,
		& th {
			&:nth-child(1) {
				border-radius: 8px 0 0 0;
			}
			&:nth-last-child(2) {
				border-radius: 0 8px 0 0;
			}
			// & > .cell {
			//   padding-left: 42px;
			// }
		}
		&::before {
			height: 0;
		}
	}
	::v-deep.el-table__empty-block {
		width: 100% !important;
	}
	.had-not-got-data {
		::v-deep .el-table__fixed-right {
			box-shadow: none;
		}
	}
	::v-deep .el-table__fixed-right {
		&::before {
			background-color: transparent;
		}
	}
	::v-deep .el-table__empty-block {
		align-items: flex-start;
	}
}
