@import "~@/styles/variables/variables.scss";

























































.over-flow {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.wid190 {
	width: 100%;
}
p {
	margin: 0;
}
