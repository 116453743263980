@import "~@/styles/variables/variables.scss";



































.mask {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 9999;
	background: rgba(0, 0, 0, 0.2);
	@include flex-center;
	.toast {
		@include flex-center;
		min-width: 300px;
		height: 100px;
		background: #fff;
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
		border-radius: 3px;
		font-weight: 500;
		color: #333;
		position: relative;

		.icon {
			width: 22px;
			min-height: 17px;
			margin-right: 12px;
		}

		.icon-close {
			position: absolute;
			top: 12px;
			right: 12px;
			transform: scale(0.8);
			font-size: 13px;
			color: #ccc;
			cursor: pointer;
		}
	}
}
