@import "~@/styles/variables/variables.scss";













































































































































































































::v-deep {
	.el-input__suffix-inner .iconfont {
		font-size: 22px;
		cursor: pointer;
	}
}
.warning-tip {
	padding: 0 17px;
	margin: 0 auto 8px;
	width: 432px;
	height: 30px;
	background: #ffebe9;
	border-radius: 16px;
	font-size: 10px;
	color: #ec6b60;
	line-height: 30px;
	box-sizing: border-box;
	span {
		font-size: 16px !important;
	}
}
