@import "~@/styles/variables/variables.scss";
























































::v-deep {
	.el-breadcrumb__separator {
		margin: 0 4px;
	}
}
.el-breadcrumb__inner {
	font-weight: normal;
	.text {
		color: #595959;
	}
	.link {
		font-weight: normal;
		color: #999;
	}
}
