@import "~@/styles/variables/variables.scss";
























































































































































.el-popper.popover-sound-setting {
	padding: 0;
	border-radius: 5px;
	overflow: hidden;

	.popper__arrow {
		display: none;
	}

	.title {
		height: 40px;
		line-height: 40px;
		background-color: $base-color-theme;
		color: #fff;
		padding-left: 20px;
	}

	.box-form {
		padding: 20px;
	}

	.content {
		display: flex;
		align-items: center;

		.sound-input {
			margin-left: 20px;
			margin-right: 10px;
			flex: 1;
		}
	}
}
