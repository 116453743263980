// 修改element-ui公共样式
/* el-message */
.el-message {
	height: 52px;
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
	border: none !important;
	border-radius: 3px !important;
	justify-content: center;
	.el-message__icon,
	.iconfont,
	.el-message__content {
		color: #fff !important;
	}
	.el-message__icon,
	.iconfont {
		font-size: 20px;
		margin-right: 8px;
	}
	&.el-message--success {
		background: $base-color-success;
	}
	&.el-message--info {
		background: $base-color-info;
	}
	&.el-message--warning {
		background: $base-color-warning;
	}
	&.el-message--error {
		background: $base-color-error;
	}
}

// element-table样式调整
.el-table--border {
	border: none;
	&::after {
		width: 0 !important;
	}
}
.el-table::before {
	left: 0;
	bottom: 0;
	width: 100%;
	height: 0 !important;
}

.el-form {
	.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label,
	.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label {
		position: relative;
		&:before {
			position: absolute;
			left: -10px;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	&.el-initial {
		.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label,
		.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label {
			position: initial;
			&:before {
				position: initial;
				left: -10px;
			}
		}
	}
}
// .el-select,.el-date-editor,el-autocomplete宽度100%
.el-select,
.el-cascader {
	width: 100%;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
	width: 100% !important;
	.el-input--prefix .el-input__inner {
		padding-left: 15px;
	}
}
.el-autocomplete {
	width: 100%;
}
// 表单防抖
.el-radio ::after {
	transition: none !important;
}
.el-icon-caret-right {
	transition: none !important;
}
.reprint-remark-form .el-form-item__error {
	transition: none !important;
}
.el-checkbox ::after {
	transition: none !important;
}
.el-input__icon {
	transition: none !important;
}
.el-tabs__active-bar {
	transition: none !important;
}
/* $confirm --> el-message-box--center */
.gc-message-box.el-message-box--center {
	position: absolute;
	top: 120px;
	left: 50%;
	transform: translateX(-50%);
	padding-bottom: 20px;
	.el-message-box__header {
		padding: 20px 24px;
	}
	.el-message-box__status {
		padding-right: 6px;
		transform: translateY(0);
	}
	.el-message-box__title {
		justify-content: flex-start;
	}
	.el-message-box__headerbtn {
		top: 20px;
		right: 24px;
	}
	.el-message-box__content {
		text-align: left;
		padding: 0 24px;
	}
	.el-message-box__message p {
		color: #666;
		line-height: 21px;
	}
	.el-message-box__btns {
		text-align: right;
		padding: 31px 24px 0;
		.el-button {
			padding: 0 24px;
			height: 32px;
			border-radius: 16px;
			border: 1px solid #d8d8d8;
			box-sizing: border-box;
		}
		button:nth-child(2) {
			border: none;
			background: $base-color-primary;
		}
	}
}

// 表单全局样式-UI设计规范
.el-form {
	.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label,
	.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label {
		position: relative;
		&:before {
			position: absolute;
			left: -10px;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	&.el-initial {
		.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label,
		.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label {
			position: initial;
			&:before {
				position: initial;
			}
		}
	}
	.el-form-item__label {
		padding-right: 20px;
		// width: 104px;
		height: 36px;
		font-size: 14px;
		font-family: SourceHanSansCN-Regular, SourceHanSansCN;
		font-weight: 400;
		color: #4e4e4e;
		line-height: 21px;
		word-break: break-all;
		word-wrap: break-word;
		box-sizing: border-box;
		&:before {
			margin-right: 7px;
		}
	}
	.el-input.is-disabled .el-input__inner,
	.el-textarea.is-disabled .el-textarea__inner {
		background: #f7f7f7;
		border: 1px solid #f7f7f7;
	}
	.el-input__inner,
	.el-textarea__inner {
		color: #4e4e4e;
		border: 1px solid #d9d9d9;
	}
	.el-select .el-input.is-disabled .el-input__inner:hover {
		border-color: #f7f7f7;
	}
	.el-textarea {
		.el-textarea__inner {
			padding-bottom: 20px;
		}
		.el-input__count {
			background-color: transparent;
			bottom: -5px;
		}
	}
}

// tooltip dark mode bgColor change
.el-tooltip__popper.is-dark {
	background: rgba(102, 102, 102, 0.85) !important;
	max-width: calc(100% - 800px) !important;
	.popper__arrow {
		border-top-color: transparent !important;
		&::after {
			border-top-color: rgba(102, 102, 102, 0.85) !important;
		}
	}
	&.popper-class-custom {
		max-width: calc(100% - 800px) !important;
	}
}

// el-button 样式覆盖
.el-button {
	height: 40px;
	font-size: 14px !important;
	padding: 0 22px;
	border-radius: 8px;
	&.el-button--primary {
		border-radius: 8px;
	}
	&.el-button--default {
		border-radius: 8px;
	}
	&.is-plain {
		border-radius: 8px;
		color: $base-color-primary;
		border-color: $base-color-primary;
	}
	&.el-button--warning.is-plain {
		color: $base-color-warning;
		border-color: $base-color-warning;
		background: white;
		&:hover,
		&:focus {
			background-color: #fdf6ec;
			color: $base-color-warning;
		}
	}
	&.el-button--large {
		padding: 0 32px;
	}
	&.is-round {
		padding: 0 24px !important;
		height: 32px;
		box-sizing: border-box;
		&.el-button--large {
			padding: 0 36px !important;
		}
	}
	&.el-button--text {
		height: auto;
		padding: 0;
	}
}

.bs-select.el-select-dropdown {
	background: $bs-bg-header;
	border-color: #7c9ddf;
	.el-select-dropdown__item.hover,
	.el-select-dropdown__item:hover {
		background: rgba(124, 189, 255, 0.2);
	}
	.el-select-dropdown__item {
		color: #fff;
	}
	.popper__arrow::after {
		border-bottom-color: $bs-bg-header !important;
	}
}
// 大屏搜索下拉框样式
.el-autocomplete-suggestion.item {
	margin-top: 20px;
	background: $bs-bg;
	border: 1px solid $bs-border-color;
	border-radius: 0;
	padding: 0 20px;
	transform: scale(var(--screen-container));
	transform-origin: left top !important;
	background: $bs-bg-header;
	&.is-loading li:hover,
	li:hover {
		background: rgba(124, 189, 255, 0.2);
	}
	&.is-loading li {
		display: block;
	}
	li {
		display: flex;
		background: rgba(124, 189, 255, 0.2);
		line-height: 40px;
		margin-top: 8px;
		&.highlighted {
			background: rgba(124, 189, 255, 0.5);
		}
		&:first-of-type {
			margin-top: 0;
		}
		span {
			color: #fff;
			&:nth-of-type(1) {
				flex: 1;
			}
			&:nth-of-type(2) {
				flex: 2;
				padding-left: 20px;
			}
			&:nth-of-type(3) {
				flex: 3;
				padding-left: 20px;
			}
		}
	}
	.popper__arrow {
		display: none;
	}
}

// 告警推送提示无icon
.el-notification__icon.none {
	display: none;
}
