@import "~@/styles/variables/variables.scss";

















































































































.tenant-radio-list {
	margin-top: 12px;
	height: 290px;
	::v-deep .el-radio-group {
		display: flex;
		flex-wrap: wrap;
		.el-radio {
			width: 50%;
			margin: 0;
			padding: 6px 0;
		}
	}
}
.pagination {
	display: flex;
	justify-content: flex-end;
	border-top: 1px solid #f1f1f190;
	padding-top: 6px;
	margin-top: 6px;
}
