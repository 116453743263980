@import "~@/styles/variables/variables.scss";


























































.el-radio {
	width: 40%;
	margin-top: 12px;
}
