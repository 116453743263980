@import "~@/styles/variables/variables.scss";

























































































































































































































































.gc-tree-select-drop-down {
	padding: 16px 12px !important;
	max-height: 260px;
	overflow-y: scroll;
	.tree-search-block {
		margin-bottom: 6px;
	}
	.el-tree {
		.el-tree-node {
			&__content {
				height: 36px;
				border-radius: 4px;
				.el-icon-arrow-right:not(.is-leaf) {
					color: #3f435e;
					font-size: 10px;
					font-weight: bold;
				}
				.custom-tree-node {
					flex: 1;
					width: 0;
					align-items: center;
					color: #3f435e;
					font-size: 14px;
					&.disabled {
						cursor: not-allowed;
						opacity: 0.5;
						.el-dropdown-menu__item {
							color: #3f435e !important;
							font-weight: normal !important;
						}
					}
					.el-dropdown-menu__item {
						width: 100%;
						line-height: 36px;
						padding: 0;
						&:hover {
							background-color: transparent;
							color: #4d6bff;
						}
					}
				}
			}
			&.is-current {
				& > .el-tree-node__content {
					.custom-tree-node {
						.el-dropdown-menu__item {
							color: #4d6bff;
							font-weight: 600;
						}
					}
				}
			}
		}
	}
}
