@import "~@/styles/variables/variables.scss";












































.status-card {
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;
	&-item {
		height: 100px;
		background: linear-gradient(135deg, #f5f6fc 0%, #edeffa 100%);
		border-radius: 6px;
		margin-top: 20px;
		padding: 25px 0 10px 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.field-name {
			i {
				font-size: 16px;
				vertical-align: middle;
				color: #4d6bff;
			}
			span {
				font-size: 16px;
				color: #4e4e4e;
				margin-left: 10px;
				vertical-align: middle;
			}
		}
		.field-value {
			font-size: 22px;
			font-weight: 600;
			color: #4d6bff;
		}
	}
	.status-card-item.red {
		background: linear-gradient(135deg, #faf7f6 0%, #f5eeeb 100%);
		.field-name {
			i {
				color: #e5662e;
			}
		}
		.field-value {
			color: #e5662e;
		}
	}
}
