@import "~@/styles/variables/variables.scss";


















































































































































































.gc-cloud-pagination {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	color: #000000;
	font-size: 12px;
	padding: 10px 0;
	.total {
		font-weight: normal;
		color: #606266;
		font-size: 13px;
	}
	&-right {
		display: flex;
		justify-content: space-between;
		align-items: center;
		span {
			margin-right: 5px;
		}
	}
	&-left {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.65);
		.select {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 30px;
			background: #ffffff;
			border-radius: 4px;
			border: 1px solid #d9d9d9;
			margin: 0 6px;
			padding: 0 12px;
		}
	}
	.gc-cloud-pagination-right {
		.el-pagination.is-background {
			padding: 2px 0 2px 5px;
			::v-deep .el-pagination__jump {
				margin-left: 20px;
			}
		}
	}
	.only-up-next {
		display: flex;
		align-items: center;
		span {
			font-size: 12px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: rgba(0, 0, 0, 0.65);
		}
		.button {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 32px;
			height: 30px;
			color: #303030;
			font-size: 13px;
			background: #ffffff;
			border-radius: 4px;
			border: 1px solid #d9d9d9;
			margin-left: 10px;
			cursor: pointer;
		}
		.not-allow {
			cursor: not-allowed;
			color: #c0c4cc;
		}
	}
	::v-deep .el-pagination.is-background {
		& .el-pager li {
			min-width: 30px;
			height: 30px;
			background: #ffffff;
			border-radius: 4px;
			border: 1px solid #d9d9d9;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #555555;
			// &:not(.disabled).active {
			//   border-color: #f65d3a;
			//   background-color: #f65d3a;
			// }
		}
		& .btn-prev,
		& .btn-next {
			width: 32px;
			height: 30px;
			background: #ffffff;
			border-radius: 4px;
			border: 1px solid #d9d9d9;
		}
	}
	::v-deep .el-dropdown-link {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.65) !important;
	}
}
